import { createSelector } from 'reselect';

const getAppState = (state) => state.app;

export const getAPIStatus = createSelector(getAppState, (appState) => appState.apiStatus);

export const getSideBarOpen = createSelector(getAppState, (appState) => appState.sideBarOpen);

export const getProfilePromptShown = createSelector(getAppState, (appState) => appState.profilePromptShown);

export const getResourceReactions = createSelector(getAppState, (appState) => appState.reactions.resources);
export const getWorkshopReactions = createSelector(getAppState, (appState) => appState.reactions.workshops);
