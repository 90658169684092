import AppTypes from '../action-types/app';

const internals = {
    initial: () => ({
        apiStatus: {
            error: false,
            errorMsg: null,
            completed: false,
            successMsg: null,
        },
        sideBarOpen: true,
        profilePromptShown: false,
        reactions: { resources: [], workshops: [] },
    }),
};

const AppReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
        case AppTypes.GET_REACTIONS_BEGIN:
            return {
                ...state,
                reactions: { resources: [], workshops: [] },
            };
        case AppTypes.GET_REACTIONS_SUCCESS: {
            const typeResources = payload.filter((item) => item.type === 'resource');
            const typeWorkshops = payload.filter((item) => item.type === 'workshop');

            return {
                ...state,
                reactions: { resources: typeResources, workshops: typeWorkshops },
            };
        }
        case AppTypes.CONFIRM_PROFILE_PROMPT_SHOWN:
            return {
                ...state,
                profilePromptShown: true,
            };
        case AppTypes.SWITCH_SIDE_BAR:
            return {
                ...state,
                sideBarOpen: !state.sideBarOpen,
            };
        case AppTypes.API_RESET:
            return {
                ...state,
                apiStatus: {
                    error: false,
                    errorMsg: null,
                    autoHideDisabled: null,
                    completed: false,
                    successMsg: null,
                },
            };
        case AppTypes.API_ERROR:
            return {
                ...state,
                apiStatus: {
                    error: true,
                    errorMsg: payload.text,
                    autoHideDisabled: payload.autoHideDisabled,
                    completed: true,
                    successMsg: null,
                },
            };
        case AppTypes.API_SUCCESS:
            return {
                ...state,
                apiStatus: {
                    error: false,
                    errorMsg: null,
                    autoHideDisabled: payload.autoHideDisabled,
                    completed: true,
                    successMsg: payload.text,
                },
            };

        default:
        // do nothing
    }
    return state;
};

export default AppReducer;
